import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

export default class ActivateAccount extends React.Component{
    constructor(){
        super()
        this.state = {
            secondsToRedirect: 5,
            url: "https://google.com",
            activation: false,
            success: true,
        }
        this.countdown = this.countdown.bind(this)
    }

    countdown(){
        let newSecs = this.state.secondsToRedirect -1
        let timeout = setTimeout(() => {this.setState({
            secondsToRedirect: newSecs
            })
            if (newSecs === 0){
                window.location = this.state.url
                clearTimeout(timeout)
            }
        }, 1000)
    } 

    async componentDidMount(){
        const urlParams = new URLSearchParams(window.location.search);
        const url = urlParams.get('url');

        await fetch(process.env.REACT_APP_AUTH_API+"activate_account", {
            method: 'PATCH',
            headers: {
                'token': urlParams.get('t')
            }
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                this.setState({
                    url: url,
                    activation: false,
                    success: true
                })
            })
            .catch((error) => {
                this.setState({
                    url: url
                })
                if (error.message === '401') {
                    // Unauthorized
                    this.setState({
                        url: url,
                        activation: false,
                        success: false
                    })
                } else if (error.message === "500") {
                    // Internal server error
                    this.setState({
                        url: url,
                        activation: false,
                        success: false
                    })
                }   
            });
    }
    
    render(){
        return(
            <div className="baseContainer">
                <div className="background">
                    {(this.state.activation)?
                        <></>:
                        (this.state.success)?
                            <Success secondsToRedirect={this.state.secondsToRedirect} url={this.state.url} countdown={this.countdown} />:
                            <NoSuccess />
                    }
                </div>
            </div>
        )
    }
}

function Success({secondsToRedirect, url, countdown}){
    countdown()
    return(
        <>
        <h1 className="emphasized"><FormattedMessage id="activate.head" /></h1>
        <h2><Countdown secondsToRedirect={secondsToRedirect} /></h2><br/><br/>
        <FormattedMessage id={"activate.noRedirect"} />
        <a className="btn class1" href={url} >
            <FormattedMessage id={"activate.toPage"}/>
        </a>
        </>
    )
}

function NoSuccess(){
    return(
        <>
        <h1 className="err"><FormattedMessage id="activate.noSuccess" /></h1>
        </>
    )
}

const Countdown = ({secondsToRedirect}) => {
    const [timer, setTimer] = useState(secondsToRedirect);
    const intervalRef = useRef();

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
    if (timer === 0) {
        // Hier kannst du die Funktion aufrufen, die ausgeführt werden soll, wenn der Countdown abgelaufen ist.
        // Beispiel: handleCountdownFinish();
        clearInterval(intervalRef.current);
    }
    }, [timer]);

    return (
        <FormattedMessage id="activate.redirect" values={{seconds: timer}} />
    );
};
