import { FormattedMessage } from "react-intl";

export default function NotFound(){
    return(
        <div className="baseContainer">
            <div className="background">
                <h1><FormattedMessage id={"notFound.heading"} /></h1>
                <h2><FormattedMessage id={"notFound.pleaseReturn"} /></h2>
            </div>
        </div>
    )
}